var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("users-list", {
    attrs: { "extra-filters": _vm.filters, striped: "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }