<template>
  <users-list :extra-filters="filters" striped />
</template>

<script>
import UsersList from '@/components/organisms/shared/users/UsersList';
import CONFIG from './config';

export default {
  name: 'EcommerceUsersList',
  components: { UsersList },
  data: () => ({ filters: CONFIG.filters }),
};
</script>
